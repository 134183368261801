import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {API} from './constant';

@Injectable({
    providedIn: 'root'
})

export class SurveyService {
  SetName(changeStartDate: string) {
    throw new Error("Method not implemented.");
  }


    public result: any[] = [];
    public surveyViewType: any;
    public surveyModuleId: any;

    public surveyTemplateEventListener = new BehaviorSubject(0);

    public updatSurveyTemplateEventListener = new BehaviorSubject(0);

    private messageSource = new BehaviorSubject({adId: null});
    currentMessage = this.messageSource.asObservable();

    private messageSRC = new BehaviorSubject(null);
    currentMSG = this.messageSRC.asObservable();

    private changeStartDate = new BehaviorSubject<string>('');
    surveyStartDate = this.changeStartDate.asObservable();

    private changeEndDate = new BehaviorSubject<string>('');
    surveyEndDate = this.changeEndDate.asObservable();

    SetStartDate(startDate: any) {
        this.changeStartDate.next(startDate);
    }

    SetEndDate(endDate: any) {
        this.changeEndDate.next(endDate);
    }
    constructor(private http: HttpClient) {
    }

    getCatPerRank(data): Observable<any> {
        return this.http.post(API.CATEGORY_PERFORMANCE_RANK, data);
    }

    getLPR(data): Observable<any> {
        console.log('date', data);
        return this.http.post(API.LOCATION_PERFORMANCE_RANK, data);
    }

    getSurveyCoupons(data): Observable<any> {
        return this.http.post(API.SURVEYCOUPONS, data);
    }

    surveyTemplateList(filter): Observable<any> {
        return this.http.get(API.SURVEY_TEMPLATE_LIST + '?filter=' + JSON.stringify(filter));
    }

    getTemplateDetails(templateId): Observable<any> {
        return this.http.get(API.DETAILS_TEMPLATE + templateId);
    }


    addSurveyTemplate(data): Observable<any> {
        return this.http.post(API.SURVEY_TEMPLATE_ADD, data);
    }

    getSurveyTemplateDeatail(templateId) {
        return this.http.get(API.SURVEY_TEMPLATE_DETAIL + templateId);
    }


    getTemplateDetails2(templateId): Observable<any> {
        return this.http.get(API.DETAILS_TEMPLATE2 + templateId);
    }

    editTemplate(id, data) {
        return this.http.post(API.SURVEY_TEMPLATE_EDIT + id, data);
    }


    getSurveyTemplateList(val): Observable<any> {
        return this.http.get(API.SURVEY_TEMPLATE_ALL_LIST);

    }

    changeMessage(message: any) {
      this.messageSRC.next(message)
    }

    setData(data) {
        this.result = data;
    }

    getData() {
        let temp = this.result;
        this.clearData();
        return temp;
    }

    clearData() {
        this.result = undefined;
    }

    surveyList(filter): Observable<any> {
        return this.http.get(API.SURVEY_LIST + '?filter=' + JSON.stringify(filter));
    }

    surveyNameList(filter): Observable<any> {
        return this.http.get(API.SURVEY_NAME_LIST + '?filter=' + JSON.stringify(filter));
    }

    surveyReportList(filter): Observable<any> {
        let httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
        };
        let body = JSON.stringify(filter);
        return this.http.post(API.SURVEY_REPORT_LIST, body, httpOptions);
        // return this.http.get(API.SURVEY_NAME_LIST + '?filter=' + JSON.stringify(filter));
    }

    surveyQuestionAnswers(filter): Observable<any> {
        let httpOptions = {
            headers: new HttpHeaders({'Content-Type': 'application/json'})
        };
        let body = JSON.stringify(filter);
        return this.http.post(API.SURVEY_ANSWERS_LIST, body, httpOptions);
    }

    surveysList(filter): Observable<any> {
    //   if(viewType && moduleId){
    //       console.log("survey Project listby name :",viewType, moduleId);
    //     filter['projectId'] = moduleId;
    //     return this.http.get(API.SURVEY_PROJECT_LIST_BY_NAME + '?filter=' + JSON.stringify(filter));
    //   }else{
        return this.http.get(API.SURVEYS_LIST + '?filter=' + JSON.stringify(filter));
    //   }
    }
  surveysProjectList(): Observable<any> {
    return this.http.get(API.SURVEYS_PROJECT_LIST );
  }
  surveysProjectListByName(filter):Observable<any>{
      return this.http.get(API.SURVEY_PROJECT_LIST_BY_NAME + '?filter=' + JSON.stringify(filter));
  }

    getSurveyDetails(surveysId): Observable<any> {
        return this.http.get(API.DETAILS_SURVEYS + surveysId);
    }


    getSurveyDetailsForCampaign(surveysId): Observable<any> {
        return this.http.get(API.CAMPAIGN_DETAILS + surveysId);
    }


    // Surveys Service

    getSurveyDetailsByCoupon(data): Observable<any> {
        return this.http.post(API.SURVEY_DETAILS_COUPON, data);
    }

    updateSurveyCouponStatus(data): Observable<any> {
        return this.http.post(API.SURVEY_COUPON_STATUS_UPDATE, data);
    }

    addSurvey(data): Observable<any> {
        return this.http.post(API.SURVEY_ADD, data);
    }

    getSurveyDetailsById(surveyId): Observable<any> {
        return this.http.get(API.SURVEY_DETAILS_BYID + surveyId);
    }

    editSurvey(id, data) {
        return this.http.post(API.SURVEY_EDIT + id, data);
    }

    getSurveyId() {
        return this.http.get(API.SURVEY_ID_LAST);
    }

    addSurveyierAnswer(data): Observable<any> {
        return this.http.post(API.ADD_SURVEYIER_ANSWERS, data);
    }

    surveyCodeVerify(data): Observable<any> {
        return this.http.post(API.SURVEY_CODE_VERIFY, data);
    }

    deleteSurveyTemplate(data): Observable<any> {
        return this.http.delete(API.DELETE_SURVEY_TEMPLATE + data);
    }

    deleteSurvey(data): Observable<any> {
        return this.http.delete(API.DELETE_SURVEYS + data);
    }

    surveyDetailsBySurveyCode(surveyDetailId): Observable<any> {
        return this.http.get(API.SURVEY_Question_ANSWERS + '?surveyierId=' + surveyDetailId);
    }

    getSurveysId(data): Observable<any> {
        return this.http.get(API.GET_SURVEYS_ID + data);
    }

    getCouponPath(data): Observable<any> {
        return this.http.get(API.COUPON_PATH + data);
    }

    addSocialMedia(data): Observable<any> {
        return this.http.post(API.SOCIAL_MEDIA_LINK, data);
    }

    getThankyouDetails(data): Observable<any> {
        return this.http.get(API.THANK_YOU_DETAILS + data);
    }

    getQrSource(data): Observable<any> {
        return this.http.post(API.QR_SOURCE, data);
    }

    getQrScan(data): Observable<any> {
        return this.http.post(API.QR_SCAN, data);
    }

    getQrTime(data): Observable<any> {
        return this.http.post(API.QR_TIME, data);
    }

    updateConfigurationSetting(id, data): Observable<any> {
        return this.http.post(API.UPDATE_SURVEY_TEMPLATE_CONFIGURATION + id, data);
    }

    updateSurveyConfigurationSetting(id, data): Observable<any> {
        return this.http.post(API.UPDATE_SURVEY_CONFIGURATION + id, data);
    }

    editForm(message: any) {
        this.messageSource.next(message)
    }

    getThankyouMsg(surveyId):Observable<any>{
        return this.http.get(API.THANK_YOU_FETCH + surveyId);
    }

    saveThankyouMsg(data):Observable<any>{
        return this.http.post(API.THANK_YOU_SAVE,data);
    }

    getSurveyIdBySurveyDetails(id){
        return this.http.get(API.SURVEY_ID_BY_SURVEY_DETAIL +'?detailId='+ id);
    }

}
